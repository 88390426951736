import { isQueryEnabled } from '@/src/lib/react-query/isQueryEnabled';
import { QueryMeta } from '@/src/lib/react-query/types';
import { isFolderFdoc } from '@/src/modules/resources/utils/resourceTypes';
import { getWoodyResponseData } from '@/src/services/woody/utils';
import { useWoody } from '@/src/services/woody/woody';
import { convertWoodyResourceToFdoc, FolderFdoc } from '@/src/types/api';
import { isWoodyError } from '@/src/utils/error';
import { keepPreviousData, QueryObserverOptions, useQuery } from '@tanstack/react-query';
import { resourceQueryKeys } from './resourceQueryKeys';

/**
 * fetches a given folder by id
 *
 * @param queryOptions
 * @returns
 */
export const useQueryFolder = (
  resourceId?: string | null,
  queryOptions?: Partial<QueryObserverOptions<FolderFdoc>>,
  meta?: QueryMeta,
) => {
  const { client } = useWoody();

  const query = useQuery({
    queryKey: resourceQueryKeys.resource(resourceId),
    queryFn: async (params) => {
      const safeResourceId = params.queryKey[1] as string;
      const folder = convertWoodyResourceToFdoc(
        getWoodyResponseData(await client.getResource(safeResourceId)),
      );

      if (!isFolderFdoc(folder)) {
        throw new Error('resource_is_not_a_folder');
      }

      return folder;
    },
    retry: (failureCount, error) => {
      if (
        (isWoodyError(error) && [404, 401, 403].includes(error.status)) ||
        error.message === 'resource_is_not_a_folder'
      ) {
        return false;
      }

      return failureCount < 4;
    },
    placeholderData: keepPreviousData,
    refetchInterval: 45000,
    meta,
    ...queryOptions,
    enabled: isQueryEnabled([!!resourceId, queryOptions?.enabled]),
  });

  return {
    ...query,
    folder: query.data,
  };
};
