'use client';

import { NewResourceType } from '@/src/modules/resources/components/NewResource/config';
import { ApiUserMe } from '@/src/modules/user/user.types';
import type Tracker from '@openreplay/tracker';
import type trackerZustand from '@openreplay/tracker-zustand';
import { IFeatureFlag } from '@openreplay/tracker/lib/modules/featureFlags';
import { useEffect } from 'react';
import { create } from 'zustand';
import { shallow } from 'zustand/shallow';
import useWoodyStore, { useWoody } from '../services/woody/woody';
import { pick } from './store';

const trackerLogging: boolean = process.env.REACT_APP_ENV !== 'production';

type TrackerConfig = {
  projectKey: string;
  ingestPoint: string;
};

export enum TrackerEventKind {
  OPEN_NEW_FDOC_MODAL = 'open_new_fdoc_modal',
  SAVE_NEW_FDOC_MODAL = 'save_new_fdoc_modal',

  YJS_EVENT = 'yjs_event',

  INIT_LOCAL_STORAGE_INFO = 'init_local_storage_info',
  INIT_VERSION_INFO = 'init_version_info',
}

export enum TrackerIssueKind {
  PDF_FAILED_LOADING = 'pdf_failed_loading',
  PDF_FAILED_LOADING_PAGE = 'pdf_failed_loading_page',
  PDF_FAILED_RENDERING_PAGE = 'pdf_failed_rendering_page',

  LOCAL_STORAGE_LIMIT_HIT = 'local_storage_limit_hit',
}

// enum TrackerFeatureFlag {
//   DIRECT_DB_GRADUAL_ROLLOUT = 'direct-db-gradual-rollout',
// }

type TrackerIssue =
  | {
      kind: TrackerIssueKind.PDF_FAILED_LOADING;
      payload: {
        fdocId: string;
        fdocType: string;
        cdnUrl: string | null;
        width: number;
        currentPage: number;
        error?: {
          message: string;
          stack: string;
          raw: unknown;
        };
      };
    }
  | {
      kind: TrackerIssueKind.PDF_FAILED_LOADING_PAGE;
      payload: {
        index: number;
        currentPage: number;
        width: number;
        height: number;
        error?: {
          message: string;
          stack: string;
          raw: unknown;
        };
      };
    }
  | {
      kind: TrackerIssueKind.PDF_FAILED_RENDERING_PAGE;
      payload: {
        index: number;
        currentPage: number;
        width: number;
        height: number;
        error?: {
          message: string;
          stack: string;
          raw: unknown;
        };
      };
    }
  | {
      kind: TrackerIssueKind.LOCAL_STORAGE_LIMIT_HIT;
      payload: {
        hadToClear: boolean;
        before: {
          total: string;
          perKey: { [key: string]: string };
        };
        after: {
          total: string;
          perKey: { [key: string]: string };
        };
      };
    };

type TrackerEvent =
  | {
      kind: TrackerEventKind.OPEN_NEW_FDOC_MODAL;
      payload: {
        type: NewResourceType | null;
        sharedFromMobile: boolean;
      };
    }
  | {
      kind: TrackerEventKind.SAVE_NEW_FDOC_MODAL;
      payload: {
        type: NewResourceType | null;
        title: string; // obfuscated
        id: string; // obfuscated
      };
    }
  | {
      kind: TrackerEventKind.YJS_EVENT;
      payload: {
        type: string;
        [key: string]: YjsEventValue;
      };
    }
  | {
      kind: TrackerEventKind.INIT_LOCAL_STORAGE_INFO;
      payload: {
        max: string;
        current: string;
        perKey: { [key: string]: string };
      };
    }
  | {
      kind: TrackerEventKind.INIT_VERSION_INFO;
      payload: {
        webVersion?: string | undefined;
        appVersion?: string | undefined;
        desktopVersion?: string | undefined;
      };
    };

type YjsEventObject = {
  [key: string]: YjsEventValue;
};
type YjsEventValue =
  | string
  | boolean
  | number
  | null
  | undefined
  | YjsEventObject
  | YjsEventValue[];

type TrackerState = {
  libs: { tracker: typeof Tracker | null; zustand: typeof trackerZustand | null };
  // eslint-disable-next-line
  zustandPlugin: Function | null;
  tracker: Tracker | null;
  hasUser: string | null;
};

type TrackerStore = TrackerState & {
  updateLibs: (libs: {
    tracker: typeof Tracker | null;
    zustand: typeof trackerZustand | null;
  }) => void;
  init: (config: TrackerConfig) => void;
  start: () => Promise<string | undefined>;
  stop: () => void;
  updateUser: (user: ApiUserMe | null) => void;
  logEvent: (event: TrackerEvent) => void;
  logIssue: (issue: TrackerIssue) => void;

  activeFlags: IFeatureFlag[];
  setActiveFlags: (flags: IFeatureFlag[]) => void;
};

const initialState: TrackerState = {
  libs: { tracker: null, zustand: null },
  zustandPlugin: null,
  tracker: null,
  hasUser: null,
};

const useTrackerStore = create<TrackerStore>()((set, get) => ({
  ...initialState,

  activeFlags: [],
  setActiveFlags: (flags) => {
    set({ activeFlags: flags });
  },

  updateLibs: (libs) => {
    set({ libs });
  },
  init: (config) => {
    const { tracker, libs } = get();
    if (typeof window === 'undefined' || !libs.tracker || !libs.zustand || tracker) return;

    const { projectKey, ingestPoint } = config;

    if (trackerLogging) {
      console.debug('Initializing tracker with project key', projectKey);
    }

    try {
      const tracker = new libs.tracker({
        projectKey,
        ingestPoint,
        local_uuid_key: 'or-uuid',
        __DISABLE_SECURE_MODE: trackerLogging,
        domSanitizer: (node) => {
          if (!(node instanceof HTMLElement)) {
            return 0; // SanitizeLevel.Plain
          }

          const dataSet = node.dataset;

          if (dataSet.orObscured) return 1; // SanitizeLevel.Obscured
          if (dataSet.orHidden) return 2; // SanitizeLevel.Hidden

          return 0; // SanitizeLevel.Plain
        },
        network: {
          useProxy: false,
          capturePayload: false,
          ignoreHeaders: ['Authorization', 'Cookie', 'Set-Cookie'],
          sessionTokenHeader: false,
          failuresOnly: false,
          captureInIframes: true,
        },
        flags: {
          onFlagsLoad: (flags) => {
            set({ activeFlags: flags });
          },
        },
      });

      set({
        tracker,
        zustandPlugin: tracker.use(libs.zustand()),
        hasUser: null,
      });

      // We are not currently using feature flags.
      // setInterval(
      //   () => {
      //     // Reload flags every 5 minutes, this means the user might get flags removed or added,
      //     // but this allows the case where flags are disabled/enabled without a page refresh
      //     try {
      //       tracker.reloadFlags()?.catch(() => {
      //         // do nothing
      //         // not sure why but it's not caught even when wrapped with try catch...
      //       });
      //     } catch {
      //       // ignore
      //     }
      //   },
      //   1000 * 60 * 5,
      // );
    } catch (e) {
      if (trackerLogging) {
        console.error('Error initializing tracker', e);
      }
    }
  },

  start: async () => {
    const { tracker } = get();
    if (!tracker) return;

    if (trackerLogging) {
      console.debug('Starting tracker');
    }

    try {
      await tracker.start();
      return tracker.getSessionID() ?? undefined;
    } catch (e) {
      if (trackerLogging) {
        console.error('Error starting tracker', e);
      }
    }
  },

  stop: () => {
    const { tracker } = get();
    if (!tracker) return;

    try {
      tracker.stop();
    } catch (e) {
      if (trackerLogging) {
        console.error('Error stopping tracker', e);
      }
    }

    set({
      hasUser: null,
    });
  },

  updateUser: (user) => {
    const { tracker, hasUser } = get();
    if (!tracker) return;

    if (hasUser && user?.id !== hasUser) {
      if (trackerLogging) console.debug('User has changed, restarting tracker');

      // since the user has switched post setting it up
      // we start a new session
      tracker.stop();
      tracker.start();

      // We are not currently using feature flags.
      // try {
      //   tracker.reloadFlags();
      // } catch {
      //   // ignore
      // }
    }

    if (hasUser === user?.id || !user) {
      return;
    }

    try {
      tracker.setUserID(user.id);
      user.subscription.tier && tracker.setMetadata('tier', user.subscription.tier);
      tracker.setMetadata('billingCycle', user.subscription.billingCycle ?? 'unknown');
      tracker.setMetadata('email', user.email);
      user.name && tracker.setMetadata('name', user.name);
    } catch (e) {
      if (trackerLogging) {
        console.error('Error updating user id', e);
      }
    }

    set({
      hasUser: user?.id ?? null,
    });

    // We are not currently using feature flags.
    // tracker.reloadFlags()?.catch(() => {
    //   /**
    //    * SIMPLE RETRY
    //    * ⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️
    //    * this could've been called before start has finished
    //    * https://github.com/openreplay/openreplay/issues/1902
    //    *
    //    * there's no way to check whether the OR has finished the start initialization...
    //    * so we just try again after a couple seconds
    //    */

    //   let retries = 0;
    //   const MAX_RETRIES = 5;

    //   const retryReloadFlags = () => {
    //     retries += 1;
    //     setTimeout(() => {
    //       tracker.reloadFlags()?.catch((e) => {
    //         if (retries <= MAX_RETRIES) {
    //           retryReloadFlags();
    //         } else {
    //           Sentry.captureException(e);
    //         }
    //       });
    //     }, 1000);
    //   };

    //   retryReloadFlags();
    // });
  },

  logEvent: (event) => {
    const { tracker } = get();
    if (!tracker) return;

    if (trackerLogging) {
      console.debug('Logging event', event);
    }

    try {
      tracker.event(event.kind, event.payload);
    } catch (e) {
      if (trackerLogging) {
        console.error('Error logging event', e);
      }
    }
  },

  logIssue: (issue) => {
    const { tracker } = get();
    if (!tracker) return;

    if (trackerLogging) {
      console.debug('Logging issue', issue);
    }

    try {
      tracker.issue(issue.kind, issue.payload);
    } catch (e) {
      if (trackerLogging) {
        console.error('Error logging issue', e);
      }
    }
  },
}));

export const useInitializeTracker = () => {
  const { client } = useWoody();

  const { updateLibs, init, start, tracker } = useTrackerStore(
    (s) => pick(s, ['updateLibs', 'init', 'start', 'tracker']),
    shallow,
  );

  // initialize tracker on mount
  useEffect(() => {
    const projectKey = process.env.NEXT_PUBLIC_OPEN_REPLAY_API_KEY;
    const ingestPoint = process.env.NEXT_PUBLIC_OPEN_REPLAY_INGESTION_POINT;

    if (!projectKey || !ingestPoint) {
      if (trackerLogging) {
        console.error('Missing project key or ingest point');
      }
      return;
    }

    import('@openreplay/tracker').then((trackerLib) => {
      import('@openreplay/tracker-zustand').then((zustandLib) => {
        updateLibs({
          tracker: trackerLib.default,
          zustand: zustandLib.default,
        });

        init({
          projectKey,
          ingestPoint,
        });

        start().then((sessionId) => {
          if (!sessionId) return;
          useWoodyStore.getState().client.setOrSessionId(sessionId);
        });
      });
    });
  }, [updateLibs, init, start]);

  useEffect(() => {
    const sessionId = tracker?.getSessionID();
    if (!client || sessionId) return;

    client.setOrSessionId(sessionId);
  }, [client, tracker]);
};

export const updateTrackerUser = (user: ApiUserMe | null) =>
  useTrackerStore.getState().updateUser(user);
export const logTrackerEvent = (event: TrackerEvent) => useTrackerStore.getState().logEvent(event);
export const logTrackerIssue = (issue: TrackerIssue) => useTrackerStore.getState().logIssue(issue);
export const useTracker = () => useTrackerStore((s) => s.tracker, shallow);
// export const useTrackerFeatureFlags = () => useTrackerStore((s) => s.activeFlags, shallow);
// export const useTrackerFeatureFlag = (flagName: string) =>
//   useTrackerStore((s) => s.activeFlags.find((flag) => flag.key === flagName), shallow);
