import IconEnter from '@/public/images/icons/Enter.svg';
import { hasMacOSKbd } from '@/src/hooks/interaction';
import { Flex } from '@/src/modules/ui/components/Flex';
import { mediaMobile } from '@/src/modules/ui/styled-utils';
import { cssVar } from '@/src/modules/ui/theme/variables';
import styled from 'styled-components';

const KbdBase = styled.kbd`
  font-family: 'Inter', sans-serif;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3px;
  border-radius: 4px;
  padding: 0 4px;
  height: 21px;
  min-width: 21px;

  background: ${cssVar['color-bg-tertiary']};
  color: ${cssVar['color-text-secondary']};

  ${mediaMobile} {
    display: none;
  }
`;

const IconEsc = styled.span.attrs({ children: 'esc' })``;

const KbdCmdOrCtrl = () => {
  const macKeyboard = hasMacOSKbd();
  return (
    <Kbd
      style={{
        fontSize: macKeyboard ? 14 : 10,
      }}
    >
      {macKeyboard ? '⌘' : 'Ctrl'}
    </Kbd>
  );
};

const Group = styled(Flex).attrs({
  alignItems: 'center',
  gap: 'text',
})`
  ${mediaMobile} {
    display: none;
  }
`;

export const Kbd = Object.assign(KbdBase, {
  IconEnter,
  IconEsc,
  KbdCmdOrCtrl,
  Group,
});
