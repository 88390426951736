import NewNoteIcon from '@/public/images/icons/NewNote.svg';
import UploadArrowIcon from '@/public/images/icons/UploadArrow.svg';
import UrlIcon from '@/public/images/icons/Url.svg';

export enum NewResourceType {
  Link = 'page',
  Note = 'notepad',
  Upload = 'stored_file',
}

interface NewResourceItemConfig {
  icon: React.FC<React.SVGProps<SVGSVGElement>>;
  label: string;
  minLabel: string;
  value: NewResourceType;
  testId: string;
  iconStyle?: React.CSSProperties;
}

export const NewResourceTypesConfig: Record<NewResourceType, NewResourceItemConfig> = {
  [NewResourceType.Link]: {
    icon: UrlIcon,
    label: 'Add new link',
    minLabel: 'Link',
    value: NewResourceType.Link,
    testId: 'link-option',
  },
  [NewResourceType.Upload]: {
    icon: UploadArrowIcon,
    label: 'Add new upload',
    minLabel: 'Upload',
    value: NewResourceType.Upload,
    testId: 'upload-option',
  },
  [NewResourceType.Note]: {
    icon: NewNoteIcon,
    label: 'Note',
    minLabel: 'Note',
    value: NewResourceType.Note,
    testId: 'note-option',
    iconStyle: {
      marginTop: -2,
      marginLeft: -2,
    },
  },
};

export const NewResourceTypesOptions: NewResourceItemConfig[] =
  Object.values(NewResourceTypesConfig);
